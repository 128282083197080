.analysis-box-content {
    max-height: 330px;
    overflow-y: auto;
    word-break: break-word;
    white-space: break-spaces;
    text-overflow: ellipsis;
}

.analysis-box-content.app-scrollbar::-webkit-scrollbar {
    width: 16px;
}

.analysis-box-item {
    margin-top: 16px;
    position: relative;
}

.analysis-box-start {
    margin-bottom: 8px;
    padding-right: 60px;
    min-height: 40px;
}
.analysis-box-item-score {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 5px;
    top: 0;
}

.analysis-box-item-no {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #212121;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    line-height: 160%;
    position: absolute;
    top: 2px;
}

.analysis-box-item-value {
    display: inline-block;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 160%;
    font-size: 16px;
    text-indent: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: break-spaces;
}

.analysis-box-item .under-line {
    height: 1px;
    background-color: #CCCFCF;
    margin-top: 16px;
}

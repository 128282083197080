.automation_rule_details_connect-ms365-description {
    display: flex;
    border-radius: 4px;
    width: 100%;
    background-color: #EDF1F5;
    border-left: solid 4px #467599;
}

.automation_rule_details_connect-ms365-description .connect-ms365-description-title {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    line-height: 20px;
    color: #467599;
}
.automation_rule_details_connect-ms365-description svg[data-testid="ErrorIcon365Stepper"] {
    font-size: 13px;
    height: 100%;
}
.automation_rule_details_connect-ms365-description svg[data-testid="ErrorIcon365Stepper"] path{
    fill: #467599;
}
.automation_rule_details_connect-ms365-description .wrapper-title {
    display: flex;
}
.automation_rule_details_connect-ms365-description .connect-ms365-description-title .text-title{
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 8px 0;
}
.credential-card-wrapper {
    background-color: #FFFFFF;
}
.connect-ms365-description {
    display: flex;
    border-radius: 4px;
    width: 100%;

    background-color: #EDF1F5;
    margin-bottom: 24px;
}

.connect-ms365-description .connect-ms365-description-title {
    position: relative;
    bottom: 44px;
    left: 20px;
    width: 350px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    line-height: 20px;
    color: #467599;
}

.connect-mail-server-card .no-connect-mail-server-content-card .connect-ms365-description {
    display: flex;
    border-radius: 4px;
    width: 600px;
    background-color: #EDF1F5;
    height: 92px;
}

.connect-mail-server-card .no-connect-mail-server-content-card .connect-ms365-description .connect-ms365-description-title {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    left: 20px;
    bottom: 82px;
    line-height: 20px;
    color: #467599;
}

.connect-ms365-description svg[data-testid="ErrorIcon"],
.connect-mail-server-card .no-connect-mail-server-content-card .connect-ms365-description svg[data-testid="ErrorIcon"] {
    font-size: 16px;
    width: 20px;
    height: 100%;
}

.connect-ms365-description svg[data-testid="ErrorIcon"] path,
.connect-mail-server-card .no-connect-mail-server-content-card .connect-ms365-description svg[data-testid="ErrorIcon"] path {
    fill: #467599;
}

{
    margin-top: 16px;
    display: flex;
}

.connected-tenant-card {
    line-height: normal;
    font-family: 'Montserrat';
    font-size: 14px;
    color: #212121;
    padding: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    width: 400px;
}

.connected-ms-account-card {
    line-height: normal;
    font-family: 'Montserrat';
    font-size: 14px;
    color: #212121;
    margin-left: 16px;
    padding: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    width: 400px;
}

.login-ms365-account-btn {
    padding: 8px 16px;
    width: fit-content;
    height: 40px;
    line-height: normal;
    font-family: 'Montserrat';
    border: 1px solid #CCCFCF;
    font-size: 14px;
    font-weight: 600;
    margin-top: 24px;
    color: #345C8B;
    display: block;
}

.connected-tenant-card .disconnected-tenant-btn.Mui-disabled {
    color: #ccc;
}

#modal-logout-ms-365 .MuiDialogContent-root,
#modal-disconnect-tenant .MuiDialogContent-root {
    padding: 0 24px;
}

#modal-disconnect-tenant .MuiDialog-paper {
    max-width: 560px;
    height: auto;
    border-radius: 4px;
}

#modal-logout-ms-365 #alert-dialog-title,
#modal-disconnect-tenant #alert-dialog-title {
    align-items: center;
    color: #212121;
    padding: 24px 24px 16px 24px;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    max-width: 490px;
}

#modal-logout-ms-365 #alert-dialog-action .action-btn .button p,
#modal-disconnect-tenant #alert-dialog-action .action-btn .button p {
    font-family: 'Montserrat';
}


.credential-card-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


.flex-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: default;
}
.credential-card-body .MuiAlert-filledInfo svg{
    font-size: 13px;
}
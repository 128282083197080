.tenant-connection-setting-wrapper {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.tenant-connection-setting-wrapper .title-domain {
    margin-bottom: 32px;
}

.tenant-connection-setting-wrapper .title-domain span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #212121;
    margin-right: 10px;
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

@media screen and (max-width: 1535px){
    .tenant-connection-setting-wrapper .setting-ms-tenant-connection-content {
        flex-direction: column;
    }
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .tenant-connection-table {
    width: 100%;
    max-width: 700px;
    padding: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    min-height: 234px;
    height: 100%;
    box-sizing: border-box;
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .tenant-connection-table .title-side {
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .tenant-connection-table .title-side span,
.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .features-connected-to-tenant-table .title-side span {
    font-size: 16px;
    font-weight: 700;
    color: #212121;
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .tenant-connection-table .content-side {
    line-height: normal;
    font-family: 'Montserrat';
    font-size: 14px;
    color: #212121;
    padding: 16px 0 16px;
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .features-connected-to-tenant-table {
    max-width: 469px;
    margin-left: 32px;
    width: 100%;
    padding: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    height: 100%;
    min-height: 234px;
    box-sizing: border-box;
}

@media screen and (max-width:1535px) {
    .tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .features-connected-to-tenant-table {
        margin-left: 0;
        margin-top: 16px;
        max-width: 700px;
    }
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .features-connected-to-tenant-table .title-side {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .features-connected-to-tenant-table .content-side .info-record {
    display: flex;
    align-items: center;
    padding: 8px 16px;
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .features-connected-to-tenant-table .content-side .info-record .feature-title {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    min-width: 237px;
}

.tenant-connection-setting-wrapper .setting-ms-tenant-connection-content .features-connected-to-tenant-table .content-side .info-record .connection-status {
    width: 100%;
    min-width: 200px;
}

#modal-setting-disconnect-tenant .MuiPaper-root {
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
}

#modal-setting-disconnect-tenant .MuiPaper-root .MuiDialogContent-root {
    font-weight: 500;
    padding: 0 24px;
}

#modal-setting-disconnect-tenant #alert-dialog-title {
    padding: 24px 24px 16px 24px;
}

#modal-setting-disconnect-tenant .MuiPaper-root .MuiDialogContent-root .modal-setting-disconnect-tenant-content .highlighted-content {
    border-left: 4px solid #CCC;
    margin-top: 16px;
    padding-left: 24px;
}

#modal-setting-disconnect-tenant .MuiPaper-root .MuiDialogContent-root .modal-setting-disconnect-tenant-content .highlighted-content span {
    font-weight: 400;
    display: block;
}

#modal-setting-disconnect-tenant .MuiPaper-root .MuiDialogContent-root .modal-setting-disconnect-tenant-content .highlighted-content span strong {
    font-weight: 700;
}


.vertical-linear-stepper-wrapper .MuiStepLabel-iconContainer .Mui-active {
    color: white;
    padding-right: 8px;
}
.vertical-linear-stepper-wrapper .MuiStepLabel-iconContainer .Mui-active .MuiStepIcon-text{
    fill: black;
}
.vertical-linear-stepper-wrapper .MuiStepLabel-iconContainer.Mui-disabled svg{
    padding-right: 8px;
    color: white;
}
.vertical-linear-stepper-wrapper .MuiStepLabel-iconContainer.Mui-disabled .MuiStepIcon-text{
    fill: black;
}
.vertical-linear-stepper-wrapper .MuiStepLabel-iconContainer.Mui-completed svg path{
    fill: #244B77;
}
.vertical-linear-stepper-wrapper .MuiStepLabel-iconContainer .MuiStepLabel-labelContainer span{
    color: red !important;
}

.vertical-linear-stepper-wrapper svg{
    font-size: 30px;
}
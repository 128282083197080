.page-content {
    display: flex;
    padding-top: 58px;
    width: 100%;
}

.setting-page .page-content-header{
    background: transparent;
    box-shadow: unset;
}

.info-box {
    border-left: 4px solid #CCCCCC;
    padding-left: 28px;
}
.automation-rule-action-card-wrapper {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #212121;
    padding: 24px;
    margin-left: 28px;
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;

    /*background: #F9F9F9;*/
}

.automation-rule-action-card-wrapper .title-side {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.automation-rule-action-card-wrapper .title-side .info-action {
    position: relative;
    top: -1px;
    margin-left: 8px;
}

.automation-rule-action-card-wrapper .title-side .title {
    color: #212121;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Righteous&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  background: #F8F8F8;
}
/*html {*/
/*  font-size: 14px;*/
/*}*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Metropolis";
  src: url("../assets/fonts/Metropolis/Metropolis-SemiBold.eot");
  src: url("../assets/fonts/Metropolis/Metropolis-SemiBold.woff") format("woff"),
  url("../assets/fonts/Metropolis/Metropolis-SemiBold.otf") format("opentype"),
  url("../assets/fonts/Metropolis/Metropolis-SemiBold.svg#filename") format("svg");
}

p {
  font-family: "Montserrat";
}

.spinner-wrap{
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 50%;
  margin-top: -75px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 10000000;
}

.spinner-wrap:after {
  background: #fff;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: fixed;
  content: '';
  top: 0;
}

.has-scrollbar {
  overflow: scroll;
  overflow-x: hidden;
}

.has-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.has-scrollbar::-webkit-scrollbar-thumb{
  border-radius: 2px;
  border-right: 2px solid #FFFFFF;
  background-color: #B3B8B8;
}



/**********************************/
.page-content-wrapper {
  min-height: calc(100vh - 82px);
  display: flex;
  flex-direction: column;
  background: #f0f0f0;
}
.page-content-body {
  padding: 16px;
  padding-top: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page-content-body .Mui-disabled.MuiTabs-scrollButtons{
  display: none;
}

.app-paper {
  padding: 24px;
  min-height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
/**********************************/
/*.MuiList-root .Mui-selected {*/
/*  position: relative;*/
/*}*/
/*.MuiList-root .Mui-selected span{*/
/*  background-image: url("../assets/images/ico_checked.png");*/
/*  background-position: right 10px center;*/
/*  background-repeat: no-repeat;*/
/*}*/

.scan-status {
  display: inline-block;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 600;
}

.scan-status.clean {
  background: #03A9F5;
  color: #fff;
}

.scan-status.infected {
  background: #ED1C24;
  color: white;
}

.scan-status.infectedallowed {
  background: #ED1C24;
  color: white;
}

.scan-status.unscannable {
  background: #F2F2F2;
  color: #193051;
}

.scan-status.error {
  background: #F2F2F2;
  color: #193051;
}

svg[data-testid="ErrorIcon"] path{
  fill: #C80000;
}

svg[data-testid="CheckCircleIcon"] path{
  fill: #008545;
}

.action-btn .delete{
  background-color: #C80000;
}

.action-btn .agree:not(.delete){
  background-color: #345C8B;
}

.MuiPaper-root .MuiMenu-list{
  padding-top: 0;
  padding-bottom: 0;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


  /* custom scrollbar */
.app-scrollbar::-webkit-scrollbar {
  width: 20px;
}

.app-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.app-scrollbar::-webkit-scrollbar-thumb {
  background-color: #B3B8B8;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  height: 100px;
}

.app-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
